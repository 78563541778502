import React, { Component } from 'react';
import {
    Grid, Row, Col,
    FormGroup, ControlLabel, FormControl
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Card from 'components/Card/Card.jsx';
import Button from 'elements/CustomButton/CustomButton.jsx';

import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import LoaderIcon from '../../components/Helper/LoaderIcon';
import {LOGIN_STATE} from "../../constants/LogIn";
import PropTypes from "prop-types";


class LoginPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            cardHidden: true,
            badCredentials: false,
        };

        this.login = this.login.bind(this);
    }
    componentDidMount(){

        if(!this.shouldRedirect())
            setTimeout(function() { this.setState({cardHidden: false}); }.bind(this), 700);
    }

    login( values ){

        this.context.api.logIn(
            values.username,
            values.password
        );
    }
    shouldRedirect(){
        if( this.props.loggedIn === LOGIN_STATE.LOGGED_IN ) {
            const url=this.props.loginRedirect;
            return <Redirect to={url?url:'/'}/>;
        }
        return null;
    }

    renderField( field ){

        return <FormGroup>
            <ControlLabel>
                {field.label}
            </ControlLabel>
            <FormControl
                {...field.input}
                placeholder={field.placeholder}
                type={field.type}
                autoCorrect="off"
                autoCapitalize="none"
            />
        </FormGroup>;
    }

    render(){

        const { handleSubmit } = this.props;
        const loginState = this.props.loggedIn;

        return (
            <Grid>
                {this.shouldRedirect()}
                <Row>
                    <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                        <form onSubmit={ handleSubmit( this.login ) }>
                            <Card
                                hidden={this.state.cardHidden}
                                textCenter
                                title="Login."
                                content={
                                    <div>
                                        <span className="error-message">
                                            {loginState === LOGIN_STATE.BAD_CREDENTIALS? "Usuario y/o contraseña incorrectos.":""}
                                            {loginState === LOGIN_STATE.LOGIN_ERROR? "Lo sentimos, hubo un error al iniciar sesión.":""}
                                        </span>

                                        <Field
                                            name="username"
                                            label="Usuario"
                                            placeholder="Escribe tu usuario"
                                            type="text"
                                            component={this.renderField}
                                        />

                                        <Field
                                            name="password"
                                            label="Contraseña"
                                            placeholder="Escribe tu contraseña"
                                            type="password"
                                            component={this.renderField}
                                        />

                                    </div>
                                }
                                legend={
                                    <Button bsStyle="info" fill wd type="submit" disabled={Boolean(this.props.loading)} >
                                        {this.props.loading ?
                                            <LoaderIcon/> :
                                            "Login"
                                        }

                                    </Button>
                                }
                                ftTextCenter
                            />
                        </form>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

LoginPage.contextTypes = {api: PropTypes.object};


function mapStateToProps( {api, loading, loginRedirect} ){
    return {
        loggedIn: api.loggedIn,
        loading,
        loginRedirect
    };
}

function validate( values ){

    if( !values.username || ! values.password )
        return {"password":"error"};
    return {};
}

export default reduxForm({
    form: 'app_login_form',
    validate
})(
connect(mapStateToProps)(LoginPage)
);
